<template>
  <WeContainer card="" v-if="ready">
    <WeCard class="sticky-top mb-3">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="m-0"><i class="far fa-copyright"></i> {{ getTitle }}</h5>
        </div>
        <div class="col-auto">
          <div class="row align-items-center">
            <div class="col pl-0">
              <!-- Submit -->
              <WeSubmitButton
                parent-class="text-center"
                v-bind:submit-status="submit"
                v-bind:update="$route.params.id"
                v-bind:permission="checkPermission"
                v-on:submit="submitForm"
              />
              <!-- ./Submit -->
            </div>
          </div>
        </div>
      </div>
    </WeCard>

    <WeCard>
      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="form-group mb-3">
            <WeInput
              label="Şablon Adı"
              name="template_name"
              v-bind:required="true"
              v-model="form.name"
              v-bind:error="$v.form.name.$error"
            />
          </div>
        </div>
        <div class="col-12 col-lg-12">
          <div class="form-group">
            <label class="custom-label">Durum</label>
            <WeSwitch v-model="form.is_active" label="Aktif" />
          </div>
        </div>
      </div>
    </WeCard>
    <WeCard class="mt-3">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="form-group mb-3">
            <WeInput
              label="XML Adresi (Url)"
              name="template_url"
              placeholder="https://"
              v-bind:required="true"
              v-model="form.address"
              :disabled="!attributeStep"
            />
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-4">
          <div class="form-group">
            <button
              type="submit"
              v-on:click="loadAttribute()"
              class="btn btn-info"
              :disabled="!attributeStep"
            >
              <i
                :class="
                  attributeStep ? 'fa fa-download' : 'fas fa-spinner fa-spin'
                "
              ></i>
              {{
                attributeStep ? "XML Alanlarını Çek" : "XML Alanları Çekiliyor"
              }}
            </button>
          </div>
        </div>
        <div class="col-12 col-lg-12" v-if="attributeData.length > 0">
          <div class="form-group">
            <label class="custom-label">Root Element Seç (Zorunlu)</label>
            <v-select
              label="name"
              v-bind:options="rootElementList"
              v-bind:reduce="(opt) => opt.name"
              v-bind:clearable="true"
              v-model="form.rootElement"
              v-bind:required="true"
              class="setting-select"
              v-bind:error="$v.form.rootElement.$error"
            />
          </div>
        </div>
        <div
          class="position-relative table-responsive"
          v-if="attributeData.length > 0"
        >
          <!---->
          <table class="table border straight table-hover">
            <thead class="thead-light">
              <tr>
                <th width="30%" class="sortable">
                  <span>XML Alanları</span>
                </th>
                <th width="15%" class="sortable">
                  <span>Ayraç</span>
                </th>
                <th width="30%" class="sortable">
                  <span>Eşleştirilen Alanlar</span>
                </th>
                <th width="auto" class="sortable">
                  <span>Varsayılan Değer</span>
                </th>
              </tr>
              <!---->
            </thead>
            <tbody>
              <tr
                draggable="false"
                class=""
                v-for="(item, index) in xmlTemplateAttribute"
                v-bind:key="index"
                v-bind:index="index"
              >
                <td class="text-left" v-if="item.is_require">
                  {{ item.name }} <span class="text-danger">(*)</span>
                </td>
                <td class="text-left" v-else>{{ item.name }}</td>
                <td class="text-left">
                  <WeRowInput
                    name="brace"
                    size="sm"
                    placeholder="Ayraç"
                    labelClass="display-none"
                    v-model="form.brace[item.id]"
                  />
                </td>
                <td class="text-left">
                  <v-select
                    label="name"
                    size="sm"
                    inputClass="col-12 col-lg-12"
                    labelClass="display-none"
                    v-bind:multiple="true"
                    v-bind:options="attributeData"
                    v-bind:value="form.attribute[item.id]"
                    v-bind:close-on-select="false"
                    v-on:input="onInput(item.id, $event)"
                  />
                </td>
                <td>
                  <WeRowInput
                    name="default"
                    size="sm"
                    placeholder="Varsayılan Değer"
                    labelClass="display-none"
                    :disabled="form.attribute[item.id] != null"
                    v-model="form.default[item.id]"
                  />
                </td>
              </tr>
            </tbody>
            <!---->
          </table>
        </div>
      </div>
    </WeCard>
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Detail",
  data() {
    return {
      attributeStep: true,
      ready: false,
      submit: false,
      attributeData: [],
      xmlTemplateAttribute: [],
      rootElementList: [],
      form: {
        name: null,
        is_active: true,
        template: [],
        address: null,
        attribute: [],
        default: [],
        brace: [],
      },
      cleanData: {},
      cleanRequest: {},
      tmpLang: null,
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      rootElement: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("xmlTemplate", [
      "create",
      "update",
      "getById",
      "loadXmlAttribute",
      "getXmlAttribute",
    ]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    onInput(id, payload) {
      this.form.attribute[id] = payload.length <= 0 ? null : payload;
      this.form.attribute.sort();
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.form.template = this.generateTemplate();
      if (!this.checkRequireAttribute()) {
        this.$swal({
          title: "Hata",
          text: "Lütfen (*) ile işaretlenmiş zorunlu alanları doldurun ve tekrar deneyin!",
          icon: "error",
        });
        return;
      }
      this.submit = true;
      this.sendSubmitForm();
    },
    checkRequireAttribute() {
      let requiredAttribute = this.xmlTemplateAttribute.filter(
        (A) => A.is_require == true
      );
      for (let item of requiredAttribute) {
        if (
          this.form.template.filter(
            (A) => A.key == item.id && A.data.length <= 0
          ).length > 0
        ) {
          return false;
        }
      }
      return true;
    },
    sendSubmitForm() {
      if (this.$route.params.id) {
        this.cleanRequest = this.form;
        this.updateXmlTemplate();
      } else {
        this.addXmlTemplate();
      }
    },
    generateTemplate() {
      var result = [];
      this.xmlTemplateAttribute.forEach((attribute) => {
        var values = this.form.attribute[attribute.id];
        var data = [];
        if (values) {
          values.forEach((element) => {
            var attr = this.attributeData.filter((A) => A.id == element.id);
            if (attr.length > 0) {
              data.push(attr[0]);
            }
          });
        }
        result.push({
          key: attribute.id,
          data,
          detail: attribute.detail ?? [],
          brace: this.form.brace[attribute.id] ?? "",
          default: this.form.default[attribute.id] ?? "",
        });
      });
      return result;
    },
    clearAttr() {
      this.attributeStep = false;
      this.attributeData = [];
      this.rootElementList = [];
    },
    loadAttribute() {
      if (this.form.address == null || this.form.address == "") return;
      this.clearAttr();
      this.loadXmlAttribute({
        address: this.form.address,
        onSuccess: (result) => {
          this.attributeStep = true;
          if (result.data.status == "error") {
            this.$swal({
              title: "Hata",
              text: result.data.message,
              icon: "error",
            });
          } else {
            this.attributeData = result.data.data;
            this.rootElementList = result.data.roots;
          }
        },
      });
    },
    getAttributeXml() {
      this.getXmlAttribute({
        onSuccess: (result) => {
          this.xmlTemplateAttribute = result.data.items;
          this.ready = true;
        },
      });
    },
    showConfirmation(cancelButtonText, id) {
      this.$swal({
        title: "İşlem Başarılı",
        text: "Xml Şablonları listesine dönebilir veya bu sayfada kalabilirsiniz.",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: "Liste Sayfasına Dön",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.redirectToList();
        } else {
          if (id) {
            this.$router.push(`detail/${id}`);
          } else {
            window.location.reload();
          }
        }
      });
    },
    addXmlTemplate() {
      this.create({
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Detaya Git", result.data.id);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateXmlTemplate() {
      this.update({
        id: this.$route.params.id,
        form: this.cleanRequest,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Sayfada Kal");
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    getXmlTemplateById() {
      if (this.$route.params.id) {
        this.clearAttr();
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.form = result;
            this.cleanData = helper.clone(this.form);
            this.getAttributeXml();
            this.loadAttribute();
          },
        });
      } else {
        this.getAttributeXml();
      }
    },
    setChanged() {
      sessionStorage.setItem("changed", true);
    },
    removeChanged() {
      sessionStorage.removeItem("changed");
    },
    redirectToList() {
      this.$router.push("/xml-template");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    changeSlug(data) {
      this.form.slug = data;
    },
  },
  computed: {
    ...mapState(["xmlTemplate", "shared", "session"]),
    getTitle() {
      return this.$route.params.id ? "Xml Şablonu Düzenle" : "Xml Şablonu Ekle";
    },
    submitButtonText() {
      return this.$route.params.id ? "Güncelle" : "Kaydet";
    },
    checkPermission() {
      return permission.check("brand", "u");
    },
  },
  mounted() {
    this.getXmlTemplateById();
  },
};
</script>
<style>
.display-none {
  display: none;
}
.vs__dropdown-menu{
  overflow: scroll !important;
}
</style>